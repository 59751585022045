.pointer {
  cursor: pointer !important
}

.fs-8px {
  font-size: 8px !important
}

.fs-10px {
  font-size: 10px !important
}

.fs-11px {
  font-size: 11px !important
}

.fs-12px {
  font-size: 12px !important
}

.fs-13px {
  font-size: 13px !important
}

.fs-14px {
  font-size: 14px !important
}

.fs-15px {
  font-size: 15px !important
}

.fs-16px {
  font-size: 16px !important
}

.fs-17px {
  font-size: 17px !important
}

.fs-18px {
  font-size: 18px !important
}

.fs-19px {
  font-size: 19px !important
}

.fs-20px {
  font-size: 20px !important
}

.mt-10px {
  margin-top: 10px !important
}

.mt-15px {
  margin-top: 15px !important
}

.mt-20px {
  margin-top: 20px !important
}

.mt-25px {
  margin-top: 25px !important
}

.mt-30px {
  margin-top: 30px !important
}

.mt-40px {
  margin-top: 40px !important
}

.mt-50px {
  margin-top: 50px !important
}

.mt-60px {
  margin-top: 60px !important
}

.mt-70px {
  margin-top: 70px !important
}

.mt-80px {
  margin-top: 80px !important
}

.mt-90px {
  margin-top: 90px !important
}

.mt-100px {
  margin-top: 100px !important
}

.mb-10px {
  margin-bottom: 10px !important
}

.mb-20px {
  margin-bottom: 20px !important
}

.mb-30px {
  margin-bottom: 30px !important
}

.mb-40px {
  margin-bottom: 40px !important
}

.mb-50px {
  margin-bottom: 50px !important
}

.mb-60px {
  margin-bottom: 60px !important
}

.mb-80px {
  margin-bottom: 80px !important
}

.mb-100px {
  margin-bottom: 100px !important
}

.w-15px {
  width: 15px
}

.w-20px {
  width: 20px
}

.w-22px {
  width: 22px
}

.w-25px {
  width: 25px
}

.w-30px {
  width: 30px
}

.w-35px {
  width: 35px
}

.w-40px {
  width: 40px
}

.w-50px {
  width: 50px
}

.w-60px {
  width: 60px
}

.w-70px {
  width: 70px
}

.w-80px {
  width: 80px
}

.w-90px {
  width: 90px
}

.w-100px {
  width: 100px
}

.w-110px {
  width: 110px
}

.w-120px {
  width: 120px
}

.mmwh-20px {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px
}

.mmwh-22px {
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px
}

.mmwh-24px {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px
}

.mmwh-26px {
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px
}

.mmwh-28px {
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px
}

.mmwh-30px {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px
}

.mmwh-32px {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px
}

.mmwh-34px {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px
}

.mmwh-35px {
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px
}

.mmwh-36px {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px
}

.mmwh-38px {
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px
}

.mmwh-40px {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px
}

.mmw-5px {
  min-width: 5px;
  max-width: 5px
}

.mmw-6px {
  min-width: 6px;
  max-width: 6px
}

.mmw-7px {
  min-width: 7px;
  max-width: 7px
}

.mmw-8px {
  min-width: 8px;
  max-width: 8px
}

.mmw-10px {
  min-width: 10px;
  max-width: 10px
}

.mmw-20px {
  min-width: 20px;
  max-width: 20px
}

.mmw-24px {
  min-width: 24px;
  max-width: 24px
}

.mmw-25px {
  min-width: 25px;
  max-width: 25px
}

.mmw-30px {
  min-width: 30px;
  max-width: 30px
}

.mmw-35px {
  min-width: 35px;
  max-width: 35px
}

.mmw-36px {
  min-width: 36px;
  max-width: 36px
}

.mmw-40px {
  min-width: 40px;
  max-width: 40px
}

.mmw-50px {
  min-width: 50px;
  max-width: 50px
}

.mmw-60px {
  min-width: 60px;
  max-width: 60px
}

.mmw-70px {
  min-width: 70px;
  max-width: 70px
}

.mmw-80px {
  min-width: 80px;
  max-width: 80px
}

.mmw-90px {
  min-width: 90px;
  max-width: 90px
}

.mmw-100px {
  min-width: 100px;
  max-width: 100px
}

.mmw-110px {
  min-width: 110px;
  max-width: 110px
}

.mmw-115px {
  min-width: 115px;
  max-width: 115px
}

.mmw-120px {
  min-width: 120px;
  max-width: 120px
}

.mmw-140px {
  min-width: 140px;
  max-width: 140px
}

.mmw-150px {
  min-width: 150px;
  max-width: 150px
}

.mmw-155px {
  min-width: 155px;
  max-width: 155px
}

.mmw-160px {
  min-width: 160px;
  max-width: 160px
}

.mmw-170px {
  min-width: 170px;
  max-width: 170px
}

.mmw-180px {
  min-width: 180px;
  max-width: 180px
}

.mmw-190px {
  min-width: 190px;
  max-width: 190px
}

.mmw-200px {
  min-width: 200px;
  max-width: 200px
}

.mmw-210px {
  min-width: 210px;
  max-width: 210px
}

.mmw-220px {
  min-width: 220px;
  max-width: 220px
}

.mmw-240px {
  min-width: 240px;
  max-width: 240px
}

.mmw-260px {
  min-width: 260px;
  max-width: 260px
}

.mmw-280px {
  min-width: 280px;
  max-width: 280px
}

.mmw-300px {
  min-width: 300px;
  max-width: 300px
}

.mmw-320px {
  min-width: 320px;
  max-width: 320px
}

.minw-60px {
  min-width: 60px
}

.minw-80px {
  min-width: 80px
}

.minw-90px {
  min-width: 90px
}

.minw-100px {
  min-width: 100px
}

.minw-110px {
  min-width: 110px
}

.minw-120px {
  min-width: 120px
}

.minw-130px {
  min-width: 130px
}

.minw-150px {
  min-width: 150px
}

.maxw-50 {
  max-width: 50px
}

.maxw-60 {
  max-width: 60px
}

.maxw-70 {
  max-width: 70px
}

.maxw-80 {
  max-width: 80px
}

.maxw-90 {
  max-width: 90px
}

.maxw-100 {
  max-width: 100px
}

.maxw-110 {
  max-width: 110px
}

.maxw-120 {
  max-width: 120px
}

.maxw-140 {
  max-width: 140px
}

.maxw-150 {
  max-width: 150px
}

.maxw-160 {
  max-width: 160px
}

.maxw-180 {
  max-width: 180px
}

.maxw-200 {
  max-width: 200px
}

.maxw-210 {
  max-width: 210px
}

.maxw-220 {
  max-width: 220px
}

.maxw-445 {
  max-width: 445px
}

.max10em {
  max-width: 10em
}

.max15em {
  max-width: 15em
}

.max18em {
  max-width: 18em
}

.max20em {
  max-width: 20em
}

.max25em {
  max-width: 25em
}

.max26em {
  max-width: 26em
}

.max27em {
  max-width: 27em
}

.max28em {
  max-width: 28em
}

.max29em {
  max-width: 29em
}

.max30em {
  max-width: 30em
}

.max32em {
  max-width: 32em
}

.cursor-pointer {
  cursor: pointer;
}

.w-100.hidden {
  width: 100%;
  overflow: hidden;
}

@media all and (max-device-width: 720px) {
  .pagination.show-table .page-item .page-link {
    font-size: 14px;
    white-space: nowrap;
  }
}

@media all and (max-device-width: 640px) {
  .dt-custom-filter .add-new {
    margin: 5px 0px 5px 5px !important;
    float: right;
  }

  .pagination.show-table .page-item {
    white-space: pre-line;
  }

  .pagination.show-table .page-item .page-link {
    font-size: 10px;
    white-space: nowrap;
  }
}

@media all and (max-device-width: 320px) {
  .dt-custom-filter .add-new {
    margin: 5px 0px 5px 5px !important;
    float: right;
  }

  .pagination.show-table .page-item {
    white-space: pre-line;
  }

  .pagination.show-table .page-item .page-link {
    font-size: 9px;
    white-space: nowrap;
  }
}

/* Resources Page */

.tab .recources-accordian .accordion-button:not(.collapsed) {
  color: #f7aa48;
  font-weight: 600;
}

.resource-videos {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 0px rgba(141, 104, 90, 0.10);
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
}

.resource-videos h2 {
  padding: 18px 0px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 10;
  position: relative;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
}

.resource-videos:after {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.65);
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
}

.resource-videos span {
  z-index: 11;
  position: absolute;
  text-align: center;
  display: block;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  font-size: 50px;
  color: #023568;
  transform: scale(1);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.resource-videos:hover span {
  transform: scale(1.3);
  -webkit-transition: 500ms;
  transition: 500ms;
}

/* Video Modal */

.video-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  padding: 10px;
  transform: scale(1.1);
  visibility: hidden;
  opacity: 0;
  transition: 300ms ease;
}

.video-modal.active {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.video-modal video {
  display: block;
  margin: 0;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 80vh;
  outline: none;
  border: 0;
  position: relative;
  z-index: 1;
}

@media(max-width: 767px) {
  .video-modal video {
    max-height: 90vh;
  }
}

.video-modal video::-webkit-media-controls {
  filter: none;
}

.video-modal video::-webkit-media-controls-timeline {
  filter: none;
  box-shadow: none !important;
}

.video-modal video::-webkit-media-controls-panel {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAJwCAYAAABI9AvEAAACkklEQVRIS1XHdxQIZBiFcTtCkmRHqSh7U1mpJMlK9t7Jpm2WUFnZK7PsbGWWUfYohOy9NZFVznNP5/vOff/4vfdJkOD/S8iPJCJFYicJKZIGkrEi95AiuZOCFPc6KUmRyklNivsCaViR+0mR1nmAFOmcB0mR3nmIFBmcjKTIFMjMimQhRVYnGykedrKTIofzCCkeDeRkRR4jxePOE6TI5eQmxZPOU6TIE8jLiuQjRX6nACkKOoVIUdgpQoqigWKsSHFSlHBKkqKU8zQpnnGeJUXpQBlWpCwpyjnlSfGcU4EUzzsvkOJFpyIpXgpUYkVeJkVl5xVSVHFeJUVVpxopqgdqsCI1SfGaU4sUrzu1SVHHqUuKeoH6rEgDUjR0GpGisdOEFE2dZqRoHmjBirQkRSunNSnaOG1J8YbTjhRvOu1J0SHQkRXpRIrOThdSdHW6kaK78xYp3g68w4q8S4r3nPdJ8YHTgxQ9nV6k6B3ow4r0JcWHzkek6Od8TIr+zgBSDAx8wop8SorPnEGkGOwMIcVQZxgpPg8MZ0VGkGKkM4oUo50xpBjrjCPFeGcCKSYGvmBFJpFisjOFFFOdaaSY7nxJiq8CM1iRmaSY5cwmxRxnLinmOV+TYn5gASuykBSLnMWkWOIsJcUy5xtSfBtYzoqsIMVKZxUpVjtrSPGd8z0p1jrrSLE+sIEV+YEUPzobSbHJ2UyKLc5WUmwLbGdFdpBip7OLFD85P5Nit7OHFHsDv7Ai+0ix3zlAil+dg6Q45BwmxZHAUVbkGCmOOydIcdI5RYrTzhlSnA2cY0XOk+KCc5EUl5zLpLji/EaK350/SPFn4C9W5G9SXHWukeK68w8pbjg3SXErcJsVuUOKf53/7gIPXjbYDO78kgAAAABJRU5ErkJggg==) !important
}

.video-closemodal {
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: Arial;
  line-height: 1;
  background: rgba(255, 0, 0, 0.5);
  border: 1px solid rgba(255, 0, 0, 0.9);
  top: 10px;
  right: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  border-radius: 50px;
  transition: 300ms;
}

.video-closemodal:hover {
  background: rgba(255, 0, 0, 1);
  border: 1px solid rgba(255, 0, 0, 0.9);
}

.video-append video {
  border-radius: 10px;
}
ul.dropdown-menu.button-issues {
  min-width: 50px !important;
}
/* Resources Page End */

.alert-danger-2 {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.form-floating > textarea.form-control:not(:placeholder-shown){padding-top: 2.625rem;}