// ================================= //
/* core css  */
// ================================= //
// media breakpoints start
$screen-sm-max: 575.98px;
$screen-md-max: 767.98px;
$screen-lg-max: 991.98px;
$screen-xl-max: 1199.98px;
$screen-xxl-max: 1399.98px;

.z-n1 {
	z-index: -1;
}

.z-3 {
	z-index: 3 !important;
}

.z-1 {
	z-index: 1 !important;
}

@media (max-width:767.98px) {
	.order-n1 {
		order: -1;
	}
}

// media queries
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

@mixin md {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}

@mixin lg {
	@media (max-width: #{$screen-lg-max}) {
		@content;
	}
}

@mixin xl {
	@media (max-width: #{$screen-xl-max}) {
		@content;
	}
}

@mixin xxl {
	@media (max-width: #{$screen-xxl-max}) {
		@content;
	}
}

// media breakpoints end

.section-padding {
	padding: 90px 0;

	@include lg {
		padding: 70px 0;
	}

	@include md {
		padding: 50px 0;
	}
}

.bg-gradiant2 {
	background: var(--gradiant2)
}

.bg-orange {
	background-color: var(--orange);
}

.pricing-box {
	.tab-content {
		overflow-x: auto;
		.pricing-table  {
			.btn__group {
				.btn {
					margin: 0 !important;
					min-width: 100px;
				}
			}
		}
	}
}

@include md {
	.pricing .pricing-box .nav-pills button {
		font-size: 12px;
		line-height: 16px;
	}
}
.new-heading {
	color: var(--dark-blue);
	margin-bottom: 40px;
	font-size: 19px;

	@include md {
		margin-bottom: 30px;
		font-size: 16px;
	}

	h3 {
		font-size: 48px;
		font-weight: 600;
		line-height: 1.1;
		margin-bottom: 10px;

		@include xl {
			font-size: 42px;
		}

		@include lg {
			font-size: 36px;
			margin-bottom: 12px;
		}

		@include md {
			font-size: 30px;
		}

	}

	&+p {
		font-size: 20px;
	}

	h4 {
		font-size: 36px;
		font-weight: 600;
		line-height: 1.1;
		margin-bottom: 10px;

		@include lg {
			font-size: 30px;
		}

		@include md {
			font-size: 27px;
		}
	}

	h5 {
		font-size: 32px;
		font-weight: 600;
		line-height: 1.1;
		margin-bottom: 10px;

		@include lg {
			font-size: 27px;
		}

		@include md {
			font-size: 24px;
		}
	}

	&.text-white {
		* {
			color: #fff;
		}
	}
}

@include xxl {
	.container {
		max-width: 1440px !important;
		width: calc(100% - 10px) ;
	  }
}

.new-btn {
	padding: 10px 30px;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	text-decoration: none;
	font-weight: 500;
	border-radius: 30px;
	transition: all 0.3s ease-in;
	text-transform: capitalize;
	border: 1px solid transparent;
	display: inline-block;

	i {
		font-size: 26px;
		vertical-align: middle;
		margin-left: 4px;
	}

	&+.new-btn {
		margin-left: 15px;
	}

	&.btn-gradiant {
		background: var(--gradiant);
		color: #fff;
		background-size: 300%;

		&:hover {
			background: var(--gradiant);
			background-size: 200%;
		}
	}

	&.btn-white-outline {
		background-color: transparent;
		color: #fff;
		border-color: #fff;

		&:hover {
			background-color: #fff;
			color: #111;
		}
	}

	&.btn-green {
		background-color: var(--green);
		color: #fff;
		border-color: var(--green);

		&:hover {
			background-color: var(--green-dark);
			color: #fff;
		}
	}

	&.btn-cyan {
		background-color: var(--cyan);
		color: #fff;
		border-color: var(--cyan);

		&:hover {
			background-color: var(--cyan-dark);
			color: #fff;
		}
	}

	&.btn-cyan-outline {
		background-color: transparent;
		color: var(--cyan);
		border-color: var(--cyan);

		&:hover {
			background-color: var(--cyan);
			color: #fff;
		}
	}
}

.img-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.flip-x {
	animation: flip-x 3s ease-in-out infinite
}

.flip-y {
	animation: flip-y 3s ease-in-out infinite
}

.scale {
	animation: scale 3s ease-in-out infinite
}

@keyframes flip-x {

	0%,
	to {
		transform: translate(10px)
	}

	50% {
		transform: translate(-10px)
	}
}

@keyframes flip-y {

	0%,
	to {
		transform: translateY(10px)
	}

	50% {
		transform: translateY(-10px)
	}
}

@keyframes scale {

	0%,
	to {
		transform: scale(.8)
	}

	50% {
		transform: scale(1.3)
	}
}

// ================================= //
/* core css end  */
// ================================= //

// header css start 
.new-header {
	&.navbar {
		padding: 18px 0;
		background-color: #fff;

		.container {
			padding: 0 20px;
			max-width: 1440px;

			.navbar-brand {
				width: 183px;
				padding: 0;

				img {
					width: 100%;
					height: auto;
					object-fit: contain;
				}

				@include xl {
					width: 150px;
				}
			}
		}

		.navbar-toggler {
			width: 46px;
			height: 42px;
			border: none;
			margin-right: -5px;
			padding: 4px !important;
			padding-bottom: 7px !important;

			.navbar-toggler-icon {
				background: none;
				position: relative;
				width: 36px;
				height: 3px;
				transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
				-webkit-transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
				background-color: transparent;
				border-radius: 3px !important;

				&::before,
				&::after {
					content: "";
					width: 36px;
					height: 3px;
					background-color: var(--blue);
					position: absolute;
					left: 0;
					border-radius: 2px;
					transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
					-webkit-transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
				}

				&::before {
					top: 0;
					-webkit-transform: rotateZ(45deg);
					-moz-transform: rotateZ(45deg);
					-ms-transform: rotateZ(45deg);
					-o-transform: rotateZ(45deg);
					transform: rotateZ(45deg);
				}

				&::after {
					bottom: 0;
					-webkit-transform: rotateZ(-45deg);
					-moz-transform: rotateZ(-45deg);
					-ms-transform: rotateZ(-45deg);
					-o-transform: rotateZ(-45deg);
					transform: rotateZ(-45deg);
				}
			}

			border: none;

			&:focus {
				outline: none !important;
				box-shadow: none !important;
			}

			&.collapsed {
				.navbar-toggler-icon {
					background-color: var(--blue);

					&:before {
						top: -12px;
						-webkit-transform: rotateZ(0deg);
						-moz-transform: rotateZ(0deg);
						-ms-transform: rotateZ(0deg);
						-o-transform: rotateZ(0deg);
						transform: rotateZ(0deg);

						@include lg {
							top: -11px;
						}
					}

					&:after {
						bottom: -12px;
						-webkit-transform: rotateZ(0deg);
						-moz-transform: rotateZ(0deg);
						-ms-transform: rotateZ(0deg);
						-o-transform: rotateZ(0deg);
						transform: rotateZ(0deg);

						@include lg {
							bottom: -11px;
						}
					}
				}
			}

			@include md {
				width: 38px;

				.navbar-toggler-icon {
					width: 30px;

					&::before,
					&::after {
						width: 30px;
					}
				}
			}
		}

		.nav-item {
			.nav-link {
				color: #032D60;
				font-size: 16px;

				@include xxl {
					font-size: 14px;
				}
			}
		}

		@include xl {
			padding: 14px 0;

			.navbar-collapse,
			.navbar-collapse.collapsing {
				position: fixed;
				top: 70px;
				left: 0;
				background: #fff;
				padding: 0;
				z-index: 9999;
				margin: 0;
				border: 0;
				overflow-y: auto;
				bottom: 0;
				width: 100%;
				height: calc(100dvh - 70px);
				display: flex !important;
				transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
				opacity: 0;
				visibility: hidden;
				transform: translateX(100%);
				flex-flow: column;
				align-items: start;

				.navbar-nav {
					padding: 20px;
					width: 100%;
				}

				&.collapse.show {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);
				}

				.footer-action-wrapper {
					width: 100%;
				}
			}

			.nav-item {
				.nav-link {
					padding: 10px;
					font-size: 16px;
					position: relative;
					padding-right: 40px;

					&::after {
						content: "\f285";
						border: none !important;
						vertical-align: bottom;
						margin-left: auto;
						font-family: bootstrap-icons !important;
						position: absolute;
						right: 0;
					}
				}

				&+.nav-item {
					margin-top: 16px;
				}
			}

			.footer-action-wrapper {
				.nav-item {
					.nav-link {
						padding-right: 10px;

						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
}

// mega menu 
.has-mega-menu {
	.dropdown-menu {
		width: 1030px;
		border-radius: 0 0 12px 12px;
		margin-top: 21px;
		box-shadow: 0px 4rem 3rem rgba(0, 0, 0, 0.175) !important;
		padding: 0;

		.has-mega-list {
			padding: 10px 20px 20px 20px;
			list-style: none;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;

			li {
				background-color: #fff;
				padding: 16px;
				border-radius: 10px;
				border-bottom: 1px solid transparent;

				a {
					position: relative;
					padding-left: 54px;
					display: block;
					min-height: 56px;
					color: var(--blue);
					text-decoration: none;
					font-size: 14px;
					line-height: 1.25;

					span {
						position: absolute;
						width: 40px;
						height: 40px;
						left: 0;
						top: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}

					h4 {
						font-size: 16px;
						font-weight: 600;
						margin-bottom: 6px;
						line-height: 1.2;
						color: #032D60;
						margin-bottom: 6px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					p {
						margin-bottom: 0;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				&:has(.has-mega-link:hover) {
					background-color: #f8f8f8;
					border-bottom-color: #c9c9c9;
				}
			}
		}

		@include xxl {
			width: 940px;
		}

		@include xl {
			width: 100%;
			margin-top: 12px;
			box-shadow: none !important;
			padding: 0;
			border-radius: 12px;
			background-color: #f9f9f9;
			;

			.has-mega-list {
				padding: 10px;
				position: relative;

				li {
					background-color: #f9f9f9;
				}

				&::before {
					content: '';
					position: absolute;
					border-width: 0px 12px 12px 12px;
					border-style: solid;
					border-color: #f5f5f5;
					border-right-color: transparent;
					border-left-color: transparent;
					left: 40px;
					bottom: calc(100% - 1px);

					@include md {
						left: 34px;
					}
				}
			}
		}

		@include lg {
			.has-mega-list {
				grid-template-columns: 1fr 1fr;
			}
		}

		@include md {
			.has-mega-list {
				grid-template-columns: 1fr;

				li {
					padding: 10px;

					a {
						min-height: 36px;
						display: flex;
						align-items: center;
						padding: 0 24px 0 46px;

						span {
							width: 36px;
							height: 36px;
						}

						h4 {
							margin: 0;
						}

						p {
							display: none;
						}

						i {
							position: absolute;
							right: 0;
							top: 9px;
						}
					}
				}
			}
		}
	}
}


// hero css 
.new-hero-container {
	z-index: 9;
	color: #fff;

	.new-hero-content {
		font-size: 20px;
		position: relative;

		h1 {
			font-size: 56px;
			font-weight: 600;
			line-height: 1.1;
			margin-bottom: 30px;

			@include xl {
				font-size: 48px;
				margin-bottom: 20px;
			}
		}

		p {
			max-width: 640px;
			margin-inline: auto;
		}

		.new-hero-cta {
			margin-top: 40px;
		}

		@include md {
			font-size: 18px;

			h1 {
				font-size: 36px;
				margin-bottom: 16px;
			}
		}
	}

	.hero-dots {
		>img {
			position: absolute;
			top: 30px;
			left: 50%;
		}

		span {
			width: 10px;
			height: 10px;
			position: absolute;
			border-radius: 50%;

			&:nth-child(2) {
				background: green;
				top: 60px;
				left: 20%;
			}

			&:nth-child(3) {
				background: var(--maganta);
				top: 20px;
				left: 40%;
				opacity: .5;
			}

			&:nth-child(4) {
				background: var(--maganta);
				top: 80px;
				left: 59%;
			}

			&:nth-child(5) {
				border: 1px solid var(--maganta);
				top: 34%;
				left: 34%;
			}

			&:nth-child(6) {
				background: var(--cyan);
				top: 50px;
				right: 20%;
			}

			&:nth-child(7) {
				background: red;
				top: 10px;
				right: 5%;
			}
		}

	}
}

// about css 
.new-about-play {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: none !important;
	font-size: 34px;
	color: var(--cyan);
}

// meet css 
.digital-trends-bg {
	span {
		display: none;
		width: 20px;
		height: 30px;
		position: absolute;
		background-color: var(--orange);
		border-radius: 50%;
		top: 30%;
		right: 10%;
		opacity: .9;
	}
}


.digital-trends-box {
	border-radius: 60px;
	position: relative;
	z-index: 9;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: calc(100% - 50px);
		background-color: #D6E9FF;
		border-radius: 90px;
		bottom: 0;
		left: 0;
		z-index: -2;
		transition: all .3s ease-in;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: calc(100% - 70px);
		background-color: #F6F5FD;
		border-radius: 90px;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	.digital-trends-thumb {
		position: relative;
		padding-bottom: 110%;
		border-radius: 90px;
		overflow: hidden;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.digital-trends-content {
		position: absolute;
		bottom: 30px;
		left: 30px;
		right: 30px;
		background-color: #fff;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
		border-radius: 50px;
		display: flex;
		padding: 5px;
		gap: 20px;
		align-items: center;
		color: var(--dark-blue);
		transition: all .3s ease-in;

		span {
			width: 74px;
			height: 74px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
			flex-shrink: 0;
			display: grid;
			place-items: center;

			img {
				width: 40px;
			}
		}

		h4 {
			font-size: 22px;
			line-height: 1.15;
			flex: 1;
			margin: 0;
			padding-right: 20px;
		}

		@include xl {
			gap: 14px;

			h4 {
				font-size: 18px;
			}
		}

		@include md {
			bottom: 20px;
			left: 20px;
			right: 20px;
		}
	}

	&:hover,
	&.active {
		&::before {
			background-color: var(--cyan);
		}

		.digital-trends-content {
			background-color: var(--cyan);
			color: #fff;
		}
	}

	@include md {

		&::before,
		&::after {
			border-radius: 66px;
		}
	}
}

// customer css 
.custores-items {
	gap: 30px;

	.custores-item {
		width: 220px;
		border-radius: 10px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
	}

	@include md {
		gap: 20px;

		.custores-item {
			width: 160px;
		}
	}
}

// plateform css 
.platform-upper-icons {
	width: calc(100% + 30px);
	margin-left: -15px;
     
	.platform-upper-icon {
		position: relative;
		width: 20%;
		text-align: center;
		color: #fff;
		padding: 0 15px;

		span {
			width: 86px;
			height: 86px;
			display: block;
			margin: 0 auto;
		}

		h5 {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 0;
			margin-top: 12px;
		}

		&:not(:first-child)::before {
			content: '';
			position: absolute;
			background: linear-gradient(90deg, #cfcfcf6a, #cfcfcf6a 70%, transparent 70%, transparent 100%);
			height: 2px;
			width: calc(100% - 110px);
			background-size: 12px 1px;
			right: calc(50% + 54px);
			top: 45px;
			-webkit-animation: slide 30s linear infinite;
			animation: slide 30s linear infinite;
		}

		@include lg {
			h5 {
				font-size: 14px;
			}
		}
	}
    &.pui-four{
		justify-content: center;
		.platform-upper-icon {
			&:not(:first-child)::before {
				animation: slide-n 30s linear infinite;
			}
			@include md {
				width: 25%;
			}
		}
	}
	@include md {
		width: calc(100% + 20px);
		margin-left: -10px;

		.platform-upper-icon {
			padding: 0 5px;

			span {
				width: 44px;
				height: 44px;
			}

			h5 {
				font-size: 12px;

				@include sm {
					font-size: 10px;
				}
			}

			&:not(:first-child)::before {
				width: calc(100% - 60px);
				right: calc(50% + 30px);
				top: 24px;
			}
		}
	}
}

@keyframes slide {
	from {
		background-position: -512px -512px;
	}

	to {
		background-position: 0 0;
	}
}

@keyframes slide-n {
	from {
		background-position: 0 0;
	}

	to {
		background-position: -512px -512px;
	}
}

.platform-lower {
	margin: 40px 0;
    position: relative;
    height: 188px;
	.platform-lower-img {
		text-align: center;

		img {
			height: 500px;

			@include lg {
				height: 400px;
			}

			@include md {
				height: 240px;
				width: 100%;
				object-fit: contain;
			}
		}
	}
	.left {
		position: absolute;
		width: 200px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		.platform-upper-icon {
			text-align: center;
			color: #fff;
			padding: 0 15px;

			span {
				width: 86px;
				height: 86px;
				display: block;
				margin: 0 auto;
			}

			h5 {
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 0;
				margin-top: 12px;
			}

			@include lg {
				h5 {
					font-size: 14px;
				}
			}
			@include md {
				padding: 0 5px;
				span {
					width: 44px;
					height: 44px;
				}
	
				h5 {
					font-size: 12px;
	
					@include sm {
						font-size: 10px;
					}
				}
			}
		}
		@include md { 
			width: 80px;
			left:-15px;
		}
	}

	.right {
		position: absolute;
		width: 200px;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.platform-upper-icon {
			text-align: center;
			color: #fff;
			padding: 0 15px;

			span {
				width: 86px;
				height: 86px;
				display: block;
				margin: 0 auto;
			}

			h5 {
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 0;
				margin-top: 12px;
			}

			@include lg {
				h5 {
					font-size: 14px;
				}
			}
			@include md {
				padding: 0 5px;
				span {
					width: 44px;
					height: 44px;
				}
	
				h5 {
					font-size: 12px;
	
					@include sm {
						font-size: 10px;
					}
				}
			}
		}
		@include md { 
			width: 80px;
			right:-15px;
		}
	}
	.left-line {
		position: absolute;
		background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
		height: 2px;
		width: 52px;
		background-size: 12px 1px;
		top: 0px;
		animation: slide-n 30s linear infinite;
		left: 74px;
		transform: rotate(90deg);
		transform-origin: center;
		&::before {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
			height: 2px;
			width: 94px;
			background-size: 12px 1px;
			top: 1px;
			animation: slide-n 30s linear infinite;
			right: -244px;
		}

		&::after {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
			height: 2px;
			width: 68px;
			background-size: 12px 1px;
			animation: slide-n 30s linear infinite;
			bottom: 2px;
			left: 300px;
			transform: rotate(-90deg);
			transform-origin: left bottom;
		}
		@include md {
			width: 50px;
			left: 0;
			top: 30px;
			&::before{
				right: -155px;
				width: 70px;
			}
			&::after{
				display: none;
			}
		}
	}

	.right-line {
		position: absolute;
		background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
		height: 2px;
		width: 52px;
		background-size: 12px 1px;
		top: -8px;
		animation: slide-n 30s linear infinite;
		right: 74px;
		transform: rotate(-90deg);
		transform-origin: center;
		&::before {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
			height: 2px;
			width: 82px;
			background-size: 12px 1px;
			top: 1px;
			animation: slide-n 30s linear infinite;
			left: -244px;
		}

		&::after {
			content: "";
			position: absolute;
			background: linear-gradient(90deg, rgba(207, 207, 207, 0.4156862745), rgba(207, 207, 207, 0.4156862745) 70%, transparent 70%, transparent 100%);
			height: 2px;
			width: 68px;
			background-size: 12px 1px;
			animation: slide-n 30s linear infinite;
			bottom: 2px;
			right: 230px;
			transform: rotate(-90deg);
			transform-origin: left bottom;
		}

		@include md {
			width: 50px;
			right: 0;
			top: 16px;
			&::before{
				left: -163px;
				width: 50px;
			}
			&::after{
				display: none;
			}
		}
	}
	@include md {
		margin: 0;
		.platform-wrapper {
			max-width: 440px;
			margin-inline: auto;
		}
	}
}



// service css start 
.new-service-box {
	display: flex;
	flex-flow: column;
	height: 100%;
	padding: 20px 24px 24px;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 7%);
	border-radius: 12px;

	.new-service-head {
		display: flex;
		gap: 16px;
		margin-bottom: 16px;

		h4 {
			font-size: 22px;
			margin: 0;
			flex: 1;
			display: flex;
			align-items: center;
		}

		.new-service-icon {
			width: 60px;
			height: 60px;
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}
	}

	.new-service-head {
		gap: 10px;

		@include md {
			h4 {
				font-size: 20px;
			}

			.new-service-icon {
				width: 50px;
				height: 50px;
			}
		}
	}

	.new-service-intro {
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		font-size: 18px;

		p {
			margin: 0;
		}

		@include md {
			font-size: 16px;
		}
	}

	.new-service-feature {
		border-top: 1px dashed #cecece;
		margin-top: 16px;
		padding-top: 16px;

		h4 {
			font-size: 20px;
			margin-bottom: 16px;
		}

		.new-service-features {
			margin-top: 16px;
			padding: 0;
			list-style: none;

			li {
				padding-left: 24px;
				position: relative;
				color: #444;

				i {
					color: var(--cyan);
					position: absolute;
					left: 0;
					top: 1px;
				}

				&+li {
					margin-top: 10px;
				}
			}
		}

		@include md {
			h4 {
				font-size: 18px;
				margin-bottom: 12px;
			}

			.new-service-features {
				font-size: 14px;

				li {
					&+li {
						margin-top: 6px;
					}
				}
			}
		}
	}

	.new-service-footer {
		margin-top: auto;
		padding-top: 16px;
	}
}

// service tab css start 
.service-tab-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.service-tab-left {
	width: 350px;
	background-color: var(--blue);
	color: #fff;
	border-radius: 12px 0 0 12px;

	.service-tab-left-header {
		padding: 40px;
		text-align: center;

		img {
			height: 32px;
		}
	}

	@include lg {
		width: 280px;

		.service-tab-left-header {
			padding: 24px 30px;
			background: #004169;
			border-top-left-radius: 12px;

			img {
				height: 26px;
			}
		}
	}

	@include md {
		width: calc(100% + 24px);
		margin-left: -12px;
		border-radius: 0;

		.service-tab-left-header {
			border-radius: 0;
			background: transparent;
		}
	}

	.service-tab-left-body {
		padding-bottom: 60px;

		.nav-tabs {
			border: none;
			flex-flow: column;

			.nav-link {
				width: 100%;
				text-align: start;
				border: none !important;
				padding: 12px 26px;
				border-radius: 0 !important;
				display: flex;
				align-items: center;
				gap: 16px;

				img {
					flex-shrink: 0;
					width: 40px;
					height: 40px;
					object-fit: contain;
				}

				i {
					font-size: 22px;
					color: #fff;
					width: 24px;
					height: 24px;
					display: grid;
					place-items: center;

					@include md {
						color: var(--cyan);
					}
				}

				h4 {
					font-size: 22px;
					color: #fff;
					font-weight: 400;
					min-height: 48px;
					margin: 0;
					display: flex;
					align-items: center;
				}

				&.active {
					background-color: var(--cyan);

					h4 {
						color: #fff;
					}
				}
			}
		}

		@include lg {
			padding-bottom: 30px;

			.nav-tabs {
				.nav-link {
					padding: 10px 20px;
					gap: 10px;

					img {
						width: 36px;
						height: 36px;
					}

					h4 {
						font-size: 18px;
						min-height: 44px;
					}
				}
			}
		}

		@include md {
			padding: 15px;
			overflow-y: hidden;
			overflow-x: auto;
			background: #fafafa;

			&::-webkit-scrollbar {
				display: none;
			}

			.nav-tabs {
				flex-flow: row;
				gap: 8px;

				.nav-link {
					padding: 14px 20px;
					gap: 8px;
					border-radius: 25px !important;
					background-color: #0176d324;

					img {
						width: 30px;
						height: 30px;
						filter: brightness(0);
						display: none;
					}

					i {
						color: var(--cyan);
						font-size: 18px;
						width: 20px;
						height: 20px;
						margin-top: -3px;
					}

					h4 {
						font-size: 14px;
						min-height: auto;
						color: var(--cyan);
						font-weight: 600;
						white-space: nowrap;
					}

					&.active {
						i {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.service-tab-right {
	width: calc(100% - 350px);
	background-color: #E8F0FB;
	border-radius: 0 12px 12px 0;
	overflow: hidden;

	.service-tab-right-body {
		padding: 40px;
		font-size: 22px;
		color: #444342;

		h3 {
			font-size: 36px;
			font-weight: 600;
			color: var(--cyan);
			margin-bottom: 20px;
		}

		img {
			margin-top: -20px;
			margin-bottom: -80px;
			margin-left: auto;
			display: block;
			margin-right: -80px;
			mix-blend-mode: darken;
		}
	}

	@include lg {
		width: calc(100% - 280px);

		.service-tab-right-body {
			padding: 30px;
			font-size: 18px;

			h3 {
				font-size: 30px;
			}

			img {
				width: 100%;
				margin-top: 0;
				margin-bottom: -40px;
			}
		}
	}

	@include md {
		width: 100%;
		border-radius: 12px;

		.service-tab-right-body {
			padding: 24px;
			font-size: 16px;

			h3 {
				font-size: 27px;
			}
		}
	}
}

.new-stats-container {
	padding: 400px 0 100px;
	background-color: var(--black);
	color: #fff;
	margin-top: -300px;
    --bgColor: #fff;
	.new-stats-wraper {
		position: relative;
		font-size: 22px;
		line-height: 1.3;
		z-index: 9;
		padding: 60px 100px;

		&::before {
			content: '';
			position: absolute;
			background-color: var(--bgColor);
			width: calc(100% - 240px);
			height: 100%;
			left: 30px;
			z-index: -1;
			top: 0;
			transform: perspective(100px) skewX(-10deg);
			border-radius: 80px 0 0 80px;
		}

		&::after {
			content: '';
			position: absolute;
			background-color: var(--bgColor);
			width: calc(100% - 240px);
			height: 100%;
			right: 30px;
			z-index: -1;
			top: 0;
			transform: perspective(100px) skewX(10deg);
			border-radius: 0 80px 80px 0;
		}

		.new-stats-items {
			width: calc(100% + 60px);
			margin-left: -30px;
			margin-bottom: -30px;
			margin-top: 60px;

			.new-stats-item {
				width: 25%;
				padding: 0 16px;
				margin-bottom: 30px;
				font-size: 20px;

				h3 {
					font-size: 40px;
					font-weight: 700;
				}

				p {
					margin-bottom: 0;
				}
			}

			&::before {
				content: "";
				position: absolute;
				height: 100px;
				width: 95%;
				background: #98BBEF;
				border-radius: 0 0 60px 60px;
				bottom: -18px;
				left: 50%;
				transform: translateX(-50%);
				z-index: -4;
			}
		}

		@include xl {
			font-size: 20px;

			.new-stats-items {
				.new-stats-item {
					font-size: 20px;

					h3 {
						font-size: 44px;
					}
				}
			}
		}

		@include lg {
			padding: 60px 80px;
			font-size: 18px;

			.new-stats-items {
				.new-stats-item {
					font-size: 18px;

					h3 {
						font-size: 30px;
					}
				}
			}
		}

		@include md {
			padding: 40px;
			background-color: var(--cyan);
			border-radius: 44px;
			font-size: 16px;
			box-shadow: 0 9px 0px 0px #98BBEF;

			.new-stats-items {
				gap: 30px;

				.new-stats-item {
					width: calc(50% - 15px);
					padding: 0;
					font-size: 16px;

					h3 {
						font-size: 27px;
					}
				}

				&::before {
					display: none;
				}
			}

			&::before,
			&::after {
				display: none;
			}
		}
	}

	@include md {
		padding: 360px 0 60px;
	}
}

.new-partner-container {
	@include md {
		img {
			width: 80px;
		}
	}
}

// bottom cta 
.bottom-cta-container {
	background-color: var(--blue);
	color: #fff;
	padding: 90px 0;

	.assets-2 {
		bottom: -70px;
		left: -170px;

		@include xxl {
			width: 50px;
			left: 20px;
		}
	}

	.assets-1 {
		top: -70px;
		right: -180px;

		@include xxl {
			width: 50px;
			right: 20px;
		}
	}

	@include md {
		padding: 60px 0;

		.assets-2,
		.assets-1 {
			width: 40px;
		}
	}
}

// footer css start 

.footer-new {
	background-color: #010C19;

	.footer-heading {
		font-size: 18px;
		padding-bottom: 0;
		margin-bottom: 16px;
		position: relative;

		span {
			position: relative;
			padding-right: 30px;
			padding-bottom: 8px;
			display: inline-block;

			&::before {
				content: '';
				position: absolute;
				width: calc(100% - 40px);
				height: 2px;
				background: #fff;
				bottom: 0;
				left: 40px;
			}

			&::after {
				content: '';
				position: absolute;
				width: 30px;
				height: 2px;
				background: var(--cyan);
				bottom: 0;
				left: 0px;
			}
		}
	}

	.social-links {
		a {
			width: auto;
			height: auto;
			border: none;

			&:hover {
				border: none;
				background: transparent !important;
			}

			&+a {
				margin-left: 14px;
			}
		}
	}

	.footer-links {
		li {
			a {
				font-size: 14px;
				color: #D9D9D9;
				position: relative;
				padding-left: 20px;

				i {
					position: absolute;
					left: 0;
					top: -1px;
				}
			}
		}
	}

	.footer-nav {
		.footer-nav-link {
			color: #D9D9D9;
			text-decoration: none;

			&:hover {
				color: var(--bs-warning)
			}
		}
	}

	.footer-conact-item {
		position: relative;
		font-size: 16px;
		color: #D9D9D9;
		padding-left: 29px;

		a {
			text-decoration: none;
			color: inherit;

			&:hover {
				color: var(--bs-warning)
			}
		}

		&+.footer-conact-item {
			margin-top: 20px;
		}

		i {
			position: absolute;
			left: 0;
			color: var(--cyan);
			font-size: 18px;
		}
	}

	.copyrights {
		background-color: transparent;
		border-top: 1px solid #fff3;
	}
}

.inner-banner {
	font-size: 18px;
}

// services cards 
.services-list-wrapper {
	display: flex;
	gap: 60px;
	align-items: center;
	flex-wrap: wrap;

	.services-content {
		width: calc(100% - 400px);

		.new-heading {
			margin: 0;

			h5 {
				opacity: .75;
				font-size: 26px;
			}
		}
	}

	.services-thumb {
		width: 340px;

		img {
			border: 1px solid #c1c1c147;
			background-color: #fff;
		}
	}

	@include lg {
		gap: 40px;

		.services-content {
			width: calc(100% - 340px);
		}

		.services-thumb {
			width: 280px;
		}
	}

	@include md {
		gap: 40px;

		.services-content {
			text-align: center;
			width: 100%;
		}

		.services-thumb {
			width: 100%;
			max-width: 340px;
			margin-inline: auto;

			@include sm {
				max-width: 280px;
			}
		}
		.services-features {
			max-width: 360px;
			text-align: start;
			margin-inline: auto;
		}
	}

}
.service-content-list {
	list-style: none;
	position: relative;
	margin: 0;
	padding: 0;

	li {
		position: relative;
		padding-left: 28px;
        &:has(.counter-icon) {
			padding-left: 44px;
		}
		h5 {
			font-size: 20px;
			font-weight: 600;
			line-height: 1.1;
			margin-bottom: 5px;

			i {
				background: linear-gradient(90deg, var(--blue), var(--cyan));
				color: transparent;
				background-clip: text;
				position: absolute;
				left: 1px;
				top: 0px;
				font-size: 20px;
				&.counter-icon {
					background: linear-gradient(90deg, var(--blue), var(--cyan));
					width: 30px;
					height: 30px;
					border-radius: 50%;
					color: #fff;
					display: grid;
					place-items: center;
					font-size: 16px;
				}
			}

			@include lg {
				font-size: 20px;
			}

			@include md {
				font-size: 18px;
			}
		}

		p {
			margin: 0;
		}

		&+li {
			margin-top: 16px;
		}
	}
}


.bottom-service-ctabox {
	padding: 36px;
	position: relative;
	background-color: #f5f5f5;
	border: 10px solid #f1f1f1;
	color: var(--dark-blue);
	font-size: 18px;
	padding-left: 50%;

	h4 {
		font-size: 30px;
		font-weight: 600;
	}

	img {
		position: absolute;
		height: 100%;
		width: 50%;
		object-fit: cover;
		top: 0;
		bottom: 0;
		left: 0;
		transform: scaleX(-1);
		clip-path: polygon(50px 0%, 100% 0, 100% 100%, 0% 100%);
	}

	@include md {
		padding: 0%;
		display: flex;
		flex-flow: column-reverse;

		&>div {
			padding: 20px !important;
		}

		h4 {
			font-size: 24px;
		}

		img {
			position: relative;
			width: 100%;
			clip-path: none;
		}
	}
	&.right-img{        
		padding-left: 0%;
		padding-right: 50%;
		img {
			left: auto;
			right:0;
			transform: scaleX(1);
			clip-path: polygon(30px 0%, 100% 0, 100% 100%, 0% 100%);
		}
		@include md {
			padding: 0%;
			img {
				clip-path: none;
			}
		}
	}
}


// case study css 
.case-study-wrapper {
	.owl-stage-outer {
		padding: 5px 20px 20px;
		width: calc(100% + 40px);
		margin-left: -20px;
	}

	.case-study-card {
		padding: 15px;
		box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
		border-radius: 20px;
		transition: all .3s ease-in;

		&:hover {
			box-shadow: 0 1px 1px rgb(0 0 0 / 6%);

			.case-study-thumb::before {
				transform: scale(1);
			}

			.case-study-cta {
				a {
					svg {
						opacity: 1;
						visibility: visible;
					}
				}
			}

		}

		.case-study-thumb {
			width: 100%;
			padding-bottom: 62%;
			border-radius: 12px;
			display: block;
			overflow: hidden;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				top: 0;
				left: 0;
			}

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: var(--blue);
				top: 0;
				left: 0;
				z-index: 9;
				transform-origin: center;
				transform: scale(0);
				transition: all .3s ease-in;
				opacity: .1;
			}
		}

		.case-study-content {
			margin: 15px 0;
			text-align: center;
			padding-inline: 10px;
			color: var(--dark-blue);

			h4 {
				font-size: 24px;
				font-weight: 600;
			}

			.case-study-inn {
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
				opacity: .75;
			}
		}

		.case-study-cta {
			text-align: center;

			a {
				text-decoration: none;
				font-weight: 600;
				font-size: 17px;
				color: var(--cyan);
				transition: all .3s ease-in;

				svg {
					width: 26px;
					height: 26px;
					vertical-align: top;
					margin-left: 3px;
					opacity: 0;
					visibility: hidden;
				}

				&:hover {
					color: var(--blue);
				}
			}
		}

	}
}


// review css 
.new-review-wrapper {
	.owl-stage-outer {
		width: calc(100% + 30px);
		margin-left: -15px;
		padding: 15px;

		.new-review-card {
			padding: 24px;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			transition: all 0.3s ease-in;
			height: 100%;
			text-align: start;

			.new-review-head {
				padding-right: 100px;
				display: flex;
				gap: 20px;
				margin-bottom: 20px;
				position: relative;

				&>img {
					position: absolute;
					width: 50px !important;
					right: 24px;
					top: 20px;
				}

				.new-review-head-thumb {
					width: 66px;
    				height: 66px;
					border-radius: 50%;
					border: 2px solid #ccc;
					flex-shrink: 0;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.new-review-head-content {
					display: flex;
					flex-flow: column;
					justify-content: center;
					flex: 1;
					color: #333;

					h5 {
						font-size: 22px;
						font-weight: 600;
						color: var(--dark-blue);
						margin-bottom: 5px;
					}

					h6 {
						font-size: 18px;
						color: #444;
					}
				}

			}

			.new-review-body {
				font-size: 18px;
			}
		}

		@include lg {
			.owl-stage {
				align-items: start;
			}
		}

		@include md {
			.new-review-card {
				padding: 20px;

				.new-review-head {
					gap: 10px;
					padding-right: 60px;

					&>img {
						width: 30px !important;
						right: 10px;
						top: 10px;
					}

					.new-review-head-thumb {
						width: 56px;
						height: 56px;
					}

					.new-review-head-content {
						h5 {
							font-size: 18px;
							margin: 0;
						}

						h6 {
							font-size: 16px;
						}
					}
				}

				.new-review-body {
					font-size: 16px;
				}
			}
		}
	}
}


.accordion {
	.accordion-item {
		border: none;
		border-bottom: 1px solid #ebecf0;
		padding: 0 20px;
		position: relative;
		transition: .3s;
		background: transparent !important;
		&:has(.accordion-button[aria-expanded="true"]) {
			border-radius: 0 0 20px 20px;
			box-shadow: 0 20px 20px rgba(3, 4, 28, .08);
		}
		.accordion-button:focus,
		.accordion-button {
			background: transparent !important;
			border: none !important;
			box-shadow: none !important;
			font-size: 18px;
			line-height: 24px;
			padding: 25px 0;
			position: relative;
			text-align: left;
			width: 100%;    color: #000;
			padding-right: 50px;
			&:not(.collapsed){
				color: var(--cyan) !important;
			}
			&:not(.collapsed)::after {
				content: "-";
				font-size: 30px;
				background: var(--cyan) !important;
				filter: none !important;
				color: #fff !important;
				line-height: 0 !important;
				border: none !important;
				padding-bottom: 4px;
				font-weight: 200;
			}
			&::after {
				content: "+";
				border: 1px solid #c0c2c8;
				border-radius: 50%;
				height: 26px;
				right: 0;
				font-size: 13px;
				font-family: 'FontAwesome';
				top: 22px;
				position: absolute;
				transition: none;
				width: 26px;
				background: none !important;
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 21px;
				color: #525258;
			}
			@include md {
				font-size: 18px;
				padding: 14px 0;
				padding-right: 38px;
				&::after {
					top: 13px;
				}
			}
		}
		.accordion-body {
			font-size: 17px;
			font-style: normal;
			font-weight: 400;
			line-height: 26px;
			padding: 0 30px 30px 0;
			@include md{
				font-size:15px;
				line-height:22px;
				padding: 0 0 20px 0;
			}
		}
	}
}


